@tailwind base;
@tailwind components;
@tailwind utilities;

#isPasted,
#isPasted > *,
#isPasted + * {
  width: unset !important;
}

.emoji-container div div {
  box-shadow: none !important;
  width: 16px !important;
  height: 16px !important;
  top: -2px;
}

.emoji-container div div:last-child {
  width: fit-content !important;
  height: fit-content !important;
}

iframe {
  max-width: 100%;
}

p img {
  height: auto !important;
}

p img,
embed {
  border-radius: 12px;
}

#coop-app .video-js .vjs-big-play-button {
  background-color: #5c3cff;
  opacity: 0.9;
  border: none;
}

#coop-app .video-js .vjs-big-play-button:hover {
  opacity: 1;
}

#coop-app .video-js .vjs-control-bar {
  background-color: rgba(92, 60, 255, 0.9);
}

.tribute-container {
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}

#coop-app .tribute-container ul {
  margin: 0;
}

#coop-app .tribute-container li {
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: white;
  padding-right: 8px;
  padding-left: 8px;
}

#coop-app .tribute-container li.highlight {
  background-color: #eee;
}

.tribute-container img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

#coop-app .froala .fr-tribute {
  background-color: #cfe9ff;
}

#coop-app .froala .fr-tribute,
#coop-app .fr-tribute {
  font-weight: 600;
}
#coop-app .fr-box.fr-basic .fr-element {
  font-size: 16px;
}

/* .safari-browser *:not(:first-child) {
  display: none;
} */

.safari-browser p {
  display: inline;
}

.safari-browser p::after {
  content: ' \A';
  white-space: pre;
}

@media (max-width: 1024px) {
  #coop-app .video-js .vjs-big-play-button {
    font-size: 1.5em;
  }
  .ios.vjs-youtube-mobile .vjs-big-play-button {
    display: flex !important;
  }
  .ios.vjs-youtube-mobile.vjs-has-started .vjs-big-play-button {
    display: none !important;
  }
  .android.vjs-youtube .vjs-poster {
    position: relative;
  }
  .android.vjs-youtube .vjs-big-play-button {
    display: none !important;
  }
  .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1 !important;
    z-index: 1000;
    pointer-events: all !important;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 250 100% 62%; /* #5c3cff */
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 250 100% 62%; /* #5c3cff */

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 250 100% 62%; /* #5c3cff */

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 250 100% 62%; /* #5c3cff */

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
