.froala,
.froala.fr-box.fr-basic .fr-wrapper,
.froala.fr-box.fr-basic .fr-toolbar {
  border: none;
}

.fr-toolbar.fr-desktop.fr-bottom.fr-basic,
.fr-toolbar.fr-mobile.fr-bottom.fr-basic {
  position: absolute;
  bottom: -57px;
  left: -68px;
  border-image-width: none;
  background-color: transparent;
}

.editable-froala .fr-toolbar.fr-desktop.fr-bottom.fr-basic {
  left: -10px;
}

.froala .fr-desktop .fr-command:hover:not(.fr-table-cell) {
  background: rgb(231 229 228 / 0.6);
}

.froala.fr-box.fr-basic.fr-bottom .fr-wrapper {
  border-radius: 0;
}

.froala .fr-toolbar .fr-newline {
  display: none;
}

.froala .fr-toolbar .fr-btn-grp,
.froala button[id^='insertImage'],
.froala button[id^='insertImage'] svg.fr-svg {
  margin: 0;
}

.froala .fr-toolbar button[id^='insertImage'].fr-command.fr-btn {
  margin: 0;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  transition-duration: 0.15s;
}

.fr-tooltip {
  display: none;
}

.froala .fr-toolbar .fr-command.fr-btn svg path {
  fill: rgb(168, 162, 158);
}

.froala.fr-box.fr-basic .fr-element {
  padding: 0;
}

.fr-box.fr-basic .fr-element.fr-view p {
  overflow-wrap: anywhere;
}

.froala-comment,
.froala-comment.fr-box.fr-basic .fr-wrapper,
.froala-comment.fr-box.fr-basic .fr-toolbar {
  border: none;
}

.froala-comment.fr-box.fr-basic .fr-toolbar {
  display: none;
}

.froala-comment.fr-box.fr-basic .fr-element {
  padding: 3px 6px;
}

.froala-comment .fr-wrapper {
  border-radius: 20px !important;
}

.froala .fr-popup .fr-input-line input[type='text'] {
  font-size: 16px;
}

@media (max-width: 640px) {
  .fr-toolbar.fr-mobile.fr-bottom.fr-basic,
  .fr-toolbar.fr-desktop.fr-bottom.fr-basic {
    left: -3.25rem;
  }
}
